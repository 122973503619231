/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import capitalize from 'lodash/capitalize';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import cls from 'classnames';
import { CircularProgress } from '@material-ui/core';
import processGTMevent from '../../util/processGTMEvent';
import FormGroup from 'react-bootstrap/FormGroup';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import ProgressBar from '../../components/ProgressBar';
import InputButton from '../../components/InputButton';
import TextField from '../../components/TextField';
import {
  ROUTES,
  COMMERCIAL_EQUIPMENT_TYPES,
  PURCHASE_SOURCE,
  COMMERCIAL_EQUIPMENT,
} from '../../util/constants';
import AmountRangeSelector from '../../components/AmountRangeSelector';
import FindMakeFilter from '../../components/FindMakeFilter';
import Tooltip from '../../components/Tooltip';
import rightnormal from '../../../assets/image/right-normal.svg';
import CustomCard from './CustomCard';
import { findPartnerandDescription } from '../../util/helpers';
import DescriptionTextPartner from '../../components/DescriptionPartner';
import '../../components/input-field.scss';
import './carfinance.scss';

const CommercialEquipmentAssetType = (props) => {
  const {
    setTab,
    currentTab,
    assetType,
    isAuthenticated,
    forceNewApplication,
    manufacturingYear,
    getValidationErrorsForField,
    getAllVehicleYears,
    noErrors,
    vehicleYears,
    createQuote,
    purchaseSource,
    depositAmount,
    purchasePrice,
    onInputChange,
    scrollToNext,
    validationErrors,
    setTabComplete,
    handleHTTPError,
    commercialType,
    vehicleMake,
    vehicleModel,
    validationCheck,
    onEstimateChange,
    termVal,
    quoteInProgress,
    vehicleMakeModel,
    purchasePriceComplete,
    depositPriceComplete,
    nextFieldNameToFocus,
    progressValue,
    onLoadPageAsset,
    partnername,
    carCondition
  } = props;
  const history = useHistory();
  const [skipmakeModel, setSkipmakemodel] = useState(false);
  const [skipcommercialType, setSkipcommercialType] = useState(false);
  const maxTermVal = 7;
  const newpartnerflag = findPartnerandDescription(partnername) === null ? true : false;
  useEffect(() => {
    noErrors();
    getAllVehicleYears('');
  }, [getAllVehicleYears,noErrors]);

  useEffect(() => {
    processGTMevent(assetType, 'PQ-PageLoad', 'Page Load');
    onLoadPageAsset(COMMERCIAL_EQUIPMENT);
    if (setTab) {
      setTab('car');
      scrollToNext('year-manufactured~fullScreen');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (nextFieldNameToFocus) {
      if (
        (nextFieldNameToFocus === 'purchasePrice' ||
          nextFieldNameToFocus === 'purchase-price-selector') &&
        purchaseSource
      ) {
        return;
      }
      if (nextFieldNameToFocus === 'deposit') {
        window.location.hash = '#deposit';
        return;
      }

      scrollToNext(`${nextFieldNameToFocus}~fullScreen`);
    }
  }, [nextFieldNameToFocus, purchaseSource, scrollToNext]);

  const currentData = new Date();
  const currentYear = currentData.getFullYear();
  const vehicleYearsformatted = reverse(sortBy(vehicleYears));
  const vehicleYearsMax10 = vehicleYearsformatted.reduce((accum, yrOption, idx) => {
    if (idx > 9) return accum;
    accum.push({ name: String(yrOption), value: yrOption });
    return accum;
  }, []);
  vehicleYearsMax10.push({ name: 'Older', value: String(currentYear - 10) });

  const handleClick = () => {
    processGTMevent(assetType, 'termValue', termVal);
    processGTMevent(
      'PQCommercialFinance-Details-Next',
      'PQCommercialFinanceDetails-Next',
      'Clicked',
    );
    if (validationErrors && validationErrors.length) {
      validationCheck();
      return;
    }
    if (purchasePrice - depositAmount < 5000) {
      validationCheck('Loan Amount cannot be less than $5000.');
      return;
    }
    noErrors();
    // 22-matr-2019 removed as it should be accled only for update

    createQuote()
      .then(() => {
        // Add your code here
        setTabComplete(currentTab);

        if (isAuthenticated && forceNewApplication) {
          history.push(ROUTES.estimation);
        } else {
          history.push(ROUTES.contactDetails);
        }
      })
      .catch((error) => {
        handleHTTPError(error, history);
      });
  };

  const handleFieldChange = (fieldName, value, scrollToNextFieldOnChage) => {
    let updateValue = progressValue;
    switch (fieldName) {
      case 'manufacturingYear':
        updateValue = 10;
        break;
      case 'commercialType':
        updateValue = 20;
        break;
      case 'vehicleMake':
        updateValue = 25;
        break;
      case 'vehicleModel':
        updateValue = 30;
        break;
      case 'purchaseSource':
        updateValue = 50;
        break;
      case 'purchasePrice':
        updateValue = 55;
        onInputChange('depositAmount', 0, true);
        break;
      case 'depositAmount':
        updateValue = 60;
        break;
      default:
        updateValue = 70;
        break;
    }
    onInputChange('progress', updateValue, false);
    onInputChange(fieldName, value, scrollToNextFieldOnChage);
    if (
      fieldName === 'purchaseUse' ||
      fieldName === 'purchaseSource' ||
      fieldName === 'purchasePrice' ||
      fieldName === 'depositAmount'
    ) {
      createQuote();
    }
  };
  const onHandleNextClick = () => {
    if (!purchasePriceComplete && depositAmount) {
      onInputChange('depositAmount', 0, true);
    }
    onInputChange('purchasePriceComplete', true, false);
    processGTMevent(assetType, 'purchasePriceValue', purchasePrice);
  };

  const cardItems = [{ title: 'Be gainfully employed', tooltipText:'',link:'',linkText:''},
  { title: 'Have a clean credit history.', tooltipText:'',link:'',linkText:''},
  { title: 'Be older than 18 years of age.', tooltipText:'',link:'',linkText:'' },
  { title: 'Be a resident or citizen of Australia.', tooltipText:'',link:'',linkText:''}];

  return (
    <>
      <div className="container-section car-applicants-main-container full-width-container">
        <div className="content-body pt-4 car-applicants-main-tab-container" id="car">
          <div className="car-applicants-main-subcontainer px-0">
            <div className="row mx-0">
              <div className="col-12  text-center car-applicants-main-section">
                <Form noValidate className="car-applicants-main-form">
                  {/* for New */}
                  <FindMakeFilter
                    label={
                      <>
                        <span>When was the {assetType} manufactured?</span>
                        <Tooltip
                          label="Learn more"
                          message="The year has an impact on the rate and typically the newer the car the better the rate. You will be able to come back and change this if you are undecided."
                        />
                      </>
                    }
                    fields={vehicleYearsformatted.map((yr) => ({
                      name: String(yr),
                      value: yr,
                    }))}
                    onChange={handleFieldChange}
                    fieldName="manufacturingYear"
                    value={manufacturingYear}
                    formGroupClass="fit-screen year-manufactured"
                    btnClass="full-screen-mode"
                    labelClass="car-full-screen-label"
                    rootContainerClass="year-class car-manufactured-year"
                    loadmore
                    isFullScreen
                    isreturnObjectval
                    getvalidationerrors={getValidationErrorsForField}
                    id="year-manufactured"
                  />
                  {manufacturingYear && !skipcommercialType && (
                    <>
                      <FindMakeFilter
                        label={
                          <>
                            <span>What is the commercial asset type?</span>
                          </>
                        }
                        fields={COMMERCIAL_EQUIPMENT_TYPES}
                        onChange={handleFieldChange}
                        fieldName="commercialType"
                        rootContainerClass="find-make-container commercial-type"
                        value={commercialType && commercialType.value}
                        formGroupClass="fit-screen "
                        btnClass="full-screen-mode"
                        labelClass="car-full-screen-label"
                        enableSearch
                        isFullScreen
                        getvalidationerrors={getValidationErrorsForField}
                        id="commercial-type"
                        loadmore
                      />
                      <Form.Row
                        className={cls(
                          'skip-action-container',
                          commercialType && commercialType.value && 'hidden',
                        )}
                      >
                        <FormGroup as={Col} lg={9} md={10}>
                          <div className="skip-action-section">
                            <div className="skip-action-align">
                              <span className="notsure">Not sure? </span>
                              <button
                                type="button"
                                className="btn btn-default btn-skip-page"
                                hidden={commercialType && commercialType.value}
                                onClick={() => {
                                  setSkipcommercialType(true);
                                }}
                              >
                                Skip
                                <i className="fa fa-chevron-right" />
                              </button>
                            </div>
                          </div>
                        </FormGroup>
                      </Form.Row>
                    </>
                  )}

                  {(commercialType || skipcommercialType) && !skipmakeModel && (
                    <>
                      <div
                        className="car-rego-question-container rego
                         fit-screen centered-field small"
                      >
                        <div className="label car-main-screen-label pb-4 make-model">
                          What's the make and model of the {assetType}?
                        </div>
                        <div className="container small fit-screen">
                          <TextField
                            fieldName="vehicleMake"
                            value={(vehicleMake && vehicleMake.name) || vehicleMake}
                            label={`${capitalize(assetType)} Make`}
                            placeholder="Enter make"
                            getvalidationerrors={getValidationErrorsForField}
                            onChange={handleFieldChange}
                            inline
                            updateOnChange
                            id="vehicleMake"
                          />

                          <TextField
                            fieldName="vehicleModel"
                            value={(vehicleModel && vehicleModel.name) || vehicleModel}
                            label={`${capitalize(assetType)} Model`}
                            placeholder="Enter model"
                            getvalidationerrors={getValidationErrorsForField}
                            onChange={handleFieldChange}
                            inline
                            updateOnChange
                            id="vehicleModel"
                          />

                          <div className="button-container">
                            <button
                              type="button"
                              disabled={!vehicleMake && !vehicleModel}
                              hidden={vehicleMake && vehicleModel && vehicleMakeModel}
                              className="btn btn-primary btn-next-page"
                              onClick={() => {
                                onInputChange('vehicleMakeModel', true, false);
                                processGTMevent(
                                  'Commercial Equipment',
                                  'vehicleMakeValue',
                                  vehicleMake,
                                );
                                processGTMevent(
                                  'Commercial Equipment',
                                  'vehicleModelValue',
                                  vehicleModel,
                                );
                              }}
                            >
                              Next
                              <span className="pl-4">
                                <img src={rightnormal} alt="right" className="right-icon" />
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <Form.Row
                        className={cls(
                          'skip-action-container',
                          (vehicleMake || vehicleModel) && 'hidden',
                        )}
                      >
                        <FormGroup as={Col} lg={9} md={10}>
                          <div className="skip-action-section">
                            <div className="skip-action-align">
                              <span className="notsure">Not sure? </span>
                              <button
                                type="button"
                                className="btn btn-default btn-skip-page"
                                hidden={vehicleMake || vehicleModel}
                                onClick={() => {
                                  setSkipmakemodel(true);
                                  onInputChange('skipmakeModel', true, false);
                                }}
                              >
                                Skip
                                <i className="fa fa-chevron-right" />
                              </button>
                            </div>
                          </div>
                        </FormGroup>
                      </Form.Row>
                    </>
                  )}
                  {((carCondition !== 'New') && (skipmakeModel || vehicleMakeModel)) && (
                    <InputButton
                      label={
                        <>
                          <span>Where are you planning to purchase the asset from?</span>
                        </>
                      }
                      fields={PURCHASE_SOURCE}
                      onChange={handleFieldChange}
                      fieldName="purchaseSource"
                      btnClass="full-screen-mode"
                      value={purchaseSource}
                      labelClass="car-full-screen-label"
                      formGroupClass={`fit-screen ${
                        skipmakeModel ? 'skipped-action' : 'car-purchase-from'
                      }`}
                      getvalidationerrors={getValidationErrorsForField}
                      id="car-purchase-from"
                    />
                  )}
                  {((carCondition !== 'New' && purchaseSource) || carCondition === 'New') && (
                    <>
                      <div
                        className={cls(
                          'amount-range-container purchase-price-selector car-purchase-price',
                          'scrollable-anchor-tag',
                        )}
                      >
                        <a name="car-purchase-price" href="#car-purchase-price">
                          &nbsp;
                        </a>
                        <div className="label car-main-screen-label">
                          What is the estimated purchase price of the car?
                        </div>
                        <div>
                          <CustomCard
                            className="newCard"
                            title="To be considered for a loan you will need to:"
                            items={cardItems}
                          />
                        </div>
                        <AmountRangeSelector
                          label={
                            <>
                              <span className="label ">Purchase price</span>
                            </>
                          }
                          onChange={onInputChange}
                          fieldName="purchasePrice"
                          defaultValue={purchasePrice}
                          colsm={12}
                          colmd={8}
                          oncreateQuote={createQuote}
                          getvalidationerrors={getValidationErrorsForField}
                          minValue={5000}
                          maxValue={250000}
                          step={1000}
                        />
                      </div>

                      <div className="button-container">
                        <button
                          type="button"
                          className="btn btn-primary btn-next-page"
                          hidden={purchasePriceComplete}
                          onClick={onHandleNextClick}
                          disabled={purchasePrice - depositAmount < 5000}
                        >
                          Next
                          <span className="pl-4">
                            <img src={rightnormal} alt="right" className="right-icon" />
                          </span>
                        </button>
                      </div>
                    </>
                  )}
                  {purchasePriceComplete && (
                    <>
                      <div className="deposit scrollable-anchor-tag">
                        <a name="deposit" href="#deposit">
                          &nbsp;
                        </a>
                      </div>
                      <div className="amount-range-container deposit-price-selector">
                        <AmountRangeSelector
                          label={
                            <>
                              <span className="label">Deposit / Trade-in</span>
                            </>
                          }
                          onChange={onInputChange}
                          fieldName="depositAmount"
                          defaultValue={depositAmount}
                          colsm={12}
                          colmd={8}
                          minValue={0}
                          tooltip="Enter the combined amount for the trade in value of your current car, and any cash deposit you will be contributing to the purchase"
                          oncreateQuote={createQuote}
                          maxValue={purchasePrice - 5000}
                          step={1000}
                          disabled={purchasePrice - 5000 <= 0}
                          getvalidationerrors={getValidationErrorsForField}
                        />
                        <div className="form-row pb-4">
                          <div className=" car-applicants-loan-form col-md-5 ">
                            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}

                            <div className="car-applicants-main-loanamount">
                              <NumberFormat
                                displayType="text"
                                value={purchasePrice - depositAmount}
                                className="loan-amount-value"
                                prefix="$"
                                thousandSeparator
                              />
                            </div>
                            <label htmlFor="car-applicants-main-loanamount-label label">
                              Estimated loan amount
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className="button-container">
                        <button
                          type="button"
                          className="btn btn-primary btn-next-page"
                          hidden={depositPriceComplete}
                          onClick={() => {
                            onInputChange('depositPriceComplete', true, false);
                            processGTMevent(assetType, 'depositAmountValue', depositAmount);
                          }}
                        >
                          Next
                          <span className="pl-4">
                            <img src={rightnormal} alt="right" className="right-icon" />
                          </span>
                        </button>
                      </div>
                    </>
                  )}
                  {depositPriceComplete && ((carCondition !== 'New' && purchaseSource) || carCondition === 'New') && (
                    <div
                      className={cls(
                        'amount-range-container loan-term-selector car-loan-term',
                        'scrollable-anchor-tag',
                      )}
                    >
                      <div className="label car-main-screen-label">
                        Over what term do you want the loan?
                      </div>
                      <div className="term scrollable-anchor-tag">
                        <a name="term" href="#term">
                          &nbsp;
                        </a>
                      </div>

                      <div className="amount-range-container term-val-selector">
                        <AmountRangeSelector
                          label={
                            <>
                              <span className="label">Loan term</span>
                            </>
                          }
                          onChange={onEstimateChange}
                          fieldName="termVal"
                          defaultValue={termVal}
                          oncreateQuote={createQuote}
                          minValue={1}
                          maxValue={maxTermVal}
                          step={1}
                          termSlider
                          getvalidationerrors={getValidationErrorsForField}
                        />
                      </div>
                    </div>
                  )}
                  <div className="footer-actions fit-screen">
                    <input
                      type="button"
                      id="btnsubmit"
                      className="btn-dummy-page"
                      style={{ visibility: 'hidden' }}
                    />
                    {!!purchasePrice &&
                      purchasePriceComplete &&
                      depositPriceComplete &&
                      !!termVal &&
                      !!purchaseSource && (
                        <button
                          type="button"
                          data-test="carmainscreen-estimatepage"
                          className="btn  btn-navigate-page"
                          onClick={handleClick}
                          disabled={quoteInProgress}
                        >
                          {quoteInProgress ? (
                            <CircularProgress color="inherit" size={24} thickness={2.6} />
                          ) : (
                            <>
                              Next
                              <span className="pl-4">
                                <img src={rightnormal} alt="right" className="right-icon" />
                              </span>
                            </>
                          )}
                        </button>
                      )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-cardetails-page container-fluid sticky-bottom">
        {!newpartnerflag && (
          <DescriptionTextPartner partnerItem={findPartnerandDescription(partnername)} />
        )}
        <ProgressBar progressValue={progressValue} />
      </div>
    </>
  );
};

export default CommercialEquipmentAssetType;
